import React, { useState } from "react"
import { graphql } from "gatsby"
import capitalize from "../utils/capitalize"
import Layout from "../components/layout/Layout"
import Seo from "../components/SEO"
import ItemDisplayList from "../components/ItemDisplayList"
import Title from "../components/Title"
import SideBar from "../components/SideBar"
import Pagination from "../components/Pagination"

const CategoryTemplate = ({ pageContext, data }) => {
  const {
    allMdx: { nodes: items },
  } = data

  const category = capitalize(pageContext.category)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(5)
  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem)

  // Change page
  const paginate = pageNumber => {
    setCurrentPage(pageNumber)
  }

  return (
    <Layout>
      <Seo title={`${category} Category`} />
      <div className="blog-container" style={{ margin: "2rem" }}>
        <section className="primary">
          <Title title={category} />
          <ItemDisplayList items={currentItems} />
          <Pagination
            pagesPerPage={itemsPerPage}
            totalItems={items.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </section>
        <section className="sidebar">
          <SideBar />
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($category: String) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { categories: { eq: $category }, draft: { eq: false } }
      }
    ) {
      nodes {
        frontmatter {
          menuTitle
          categories
          description
          tags
        }
        id
        slug
      }
    }
  }
`

export default CategoryTemplate
