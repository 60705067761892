import React from "react"

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i)
  }

  const handleSubmit = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" })
    })
  }

  return (
    <nav>
      <div className="pagination">
        {pageNumbers.map(number => (
          <button
            className={currentPage === number ? "active " : ""}
            key={number}
            id={number}
            onClick={() => paginate(number)}
            onSubmit={handleSubmit}
          >
            {number}
          </button>
        ))}
      </div>
    </nav>
  )
}

export default Pagination
