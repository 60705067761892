// TODO: Refactor this and 'WGRecent.js' to one component

import React from "react"
import { Link } from "gatsby"
import capitalize from "../utils/capitalize"
import { useStaticQuery, graphql } from "gatsby"
import { Stack } from "@mui/material"

const WgCategories = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx {
        group(field: frontmatter___categories) {
          fieldValue
        }
      }
    }
  `)

  return (
    <Stack spacing={1}>
      <h4>Categories</h4>
      <div
        className="line"
        style={{ borderColor: "var(--clr-blue)", borderWidth: "2px" }}
      ></div>
      <Stack spacing={1}>
        {data.allMdx.group.map(category => {
          return (
            <div
              key={category.fieldValue}
              style={{ borderBottom: "1px dotted var(--clr-lightish)" }}
            >
              <Link to={`/category/${category.fieldValue}`}>
                {capitalize(category.fieldValue)}
              </Link>
            </div>
          )
        })}
      </Stack>
    </Stack>
  )
}

export default WgCategories
