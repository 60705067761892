import React from "react"
import PropTypes from "prop-types"
import { AiFillFolderOpen } from "react-icons/ai"
import { Link } from "gatsby"
import capitalize from "../utils/capitalize"

const ItemDisplay = ({ id, slug, frontmatter }) => {
  const { menuTitle, categories, description } = frontmatter

  return (
    <div style={{ display: "grid", rowGap: "var(--s0)" }}>
      <div>
        <h2 style={{ fontSize: "var(--s1)" }}>
          <Link to={`/${slug}`} key={id}>
            {menuTitle}
          </Link>
        </h2>
        <span className="with-icon item-meta" style={{ fontSize: "0.8em" }}>
          <AiFillFolderOpen className="icon" />
          <Link to={`/category/${categories}/`}>
            {capitalize(categories.toString())}
          </Link>
        </span>
      </div>
      <div>{description}</div>
      <Link className="btn-slight" to={`/${slug}`} key={id}>
        Read more...
      </Link>
    </div>
  )
}

ItemDisplay.propTypes = {
  menuTitle: PropTypes.string,
  categories: PropTypes.string,
}

ItemDisplay.defaultProps = {
  menuTitle: "Hello",
  categories: null,
}

export default ItemDisplay
