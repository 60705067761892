import React from "react"
import { Link } from "gatsby"
import { Stack } from "@mui/material"

const Recent = ({ prop, itemsName }) => {
  const items = prop.slice(0, 5)
  return (
    <Stack spacing={1}>
      <h4>Recent {itemsName}</h4>
      <div
        className="line"
        style={{ borderColor: "var(--clr-blue)", borderWidth: "2px" }}
      ></div>
      <Stack spacing={1}>
        {items.map(item => {
          return (
            <div
              key={item.frontmatter.menuTitle}
              style={{ borderBottom: "1px dotted var(--clr-lightish)" }}
            >
              <Link to={`/${item.slug}`}>{item.frontmatter.menuTitle}</Link>
            </div>
          )
        })}
      </Stack>
    </Stack>
  )
}

export default Recent
