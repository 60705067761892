// A search bar
import React from "react"
import { AiOutlineSearch } from "react-icons/ai"

const SearchBar = () => {
  return (
    <div className="search-container">
      <form className="search-form" action="/search" method="GET">
        <input
          className="search-field"
          type="text"
          placeholder="Search..."
          name="s"
        />
        <button type="submit">
          <AiOutlineSearch />
        </button>
      </form>
    </div>
  )
}

export default SearchBar
