import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import capitalize from "../utils/capitalize"
import { Button, Stack } from "@mui/material"

const WgTags = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx {
        group(field: frontmatter___tags) {
          fieldValue
        }
      }
    }
  `)

  return (
    <Stack spacing={1}>
      <h4>Tags</h4>
      <div
        className="line"
        style={{ borderColor: "var(--clr-blue)", borderWidth: "2px" }}
      ></div>
      <Stack spacing={1}>
        {data.allMdx.group.map(tag => {
          return (
            <Link key={tag.fieldValue} to={`/tag/${tag.fieldValue}`}>
              <Button variant="outlined">{capitalize(tag.fieldValue)}</Button>
            </Link>
          )
        })}
      </Stack>
    </Stack>
  )
}

export default WgTags
