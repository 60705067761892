import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SearchBar from "./SearchBar"
import Recent from "./WgRecent"
import Categories from "./WgCategories"
import Tags from "./WgTags"
import MailSub from "./MailSub"
import { Stack } from "@mui/material"

const getData = graphql`
  {
    allMdx(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { draft: { eq: false } } }
    ) {
      nodes {
        frontmatter {
          menuTitle
          categories
          description
          tags
        }
        id
        slug
      }
    }
  }
`

const SideBar = () => {
  const {
    allMdx: { nodes: guides },
  } = useStaticQuery(getData)

  return (
    <Stack spacing={4}>
      <SearchBar />
      <MailSub />
      <Recent prop={guides} itemsName="guides" />
      <Categories />
      <Tags />
    </Stack>
  )
}

export default SideBar
