import React from "react"
import ItemDisplay from "./ItemDisplay"

const ItemDisplayList = ({ items }) => {
  return (
    <main>
      {items.map(item => {
        return (
          <div key={item.id}>
            <ItemDisplay {...item} />
            <div
              style={{
                width: "20ch",
                maxWidth: "50%",
                margin: "var(--s1) auto",
              }}
            >
              <div
                className="line"
                style={{
                  borderColor: "var(--clr-greyish)",
                  borderWidth: "1px",
                }}
              ></div>
            </div>
          </div>
        )
      })}
    </main>
  )
}
export default ItemDisplayList
